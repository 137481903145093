<template>
  <div id="subpage-content">
    <ProductDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.AdditionalServices") }}</h1>
        <div id="int-about-text">
          <img height="150" width="200" :src="getImageUrlbyName('AdS1')" />
          <div id="pad" v-html="$t('message.IntGeoloc')"></div>
        </div>
        <v-dialog v-model="dialog" width="500" id="fb-bg">
          <template v-slot:activator="{ on }">
            <v-card id="buy-card">
              <v-btn v-on="on" small id="buy-btn">{{ $t("message.BuyService") }} </v-btn>
              <div id="history-card-text">
                {{ $t("message.MakeRequest") }}
              </div>
            </v-card>
          </template>
          <v-card min-height="500">
            <div id="feedback-marg">
              <Feedback />
            </div>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>

<script>
import Feedback from "../../../components/Feedback";
import ProductDrawer from "../../../views/Navigations/Products/ProductDrawer";
export default {
  name: "Integration",
  components: {
    ProductDrawer,
    Feedback,
  },
};
</script>
<style></style>
